import emoji from "react-easy-emoji";

const illustration = {
  animated: true
};

const greeting = {
  username: "Jenya Oseledets",
  title: "Привет, я Женя!",
  subTitle: emoji(
    "Я Front-end разработчик из Иркутска👨‍💻 Сейчас нахожусь в Санкт-Петербурге📍 Reactjs / JavaScript / MongoDB / Node.js 🚀"
  ),
  resumeLink:
    "https://drive.google.com/file/d/1tC_3QmoGpya1rcVnNcbgeWPsf0PCpQCH/view?usp=sharing",
  displayGreeting: true
};

const socialMediaLinks = {
  github: "https://github.com/ojenya",
  vk: "https://vk.com/id124467110",
  gmail: "jeenjenya@gmail.com",
  facebook: "https://www.facebook.com/ozhenya",
  tg: "https://t.me/oseledets",
  instagram: "https://www.instagram.com/j3nqa/",
  display: true
};

const skillsSection = {
  title: "Как я работаю",
  subTitle: "Неполный список технологий, которые мне интересны:",
  skills: [
    emoji("⚡ Сделаю Front-end / UI для сайта или мобильного приложения "),
    emoji("⚡ Сделаю приложение для ВКонтакте (VK Mini App)"),
    emoji("⚡ Интегрирую сторонние сервисы от Yandex и Google")
  ],

  softwareSkills: [
    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "Node.js",
      fontAwesomeClassname: "fab fa-node"
    },

    {
      skillName: "MongoDB",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "Я.Облако",
      fontAwesomeClassname: "fab fa-yandex"
    },
    {
      skillName: "Google Cloud",
      fontAwesomeClassname: "fab fa-google"
    },
    {
      skillName: "HTML5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "SASS",
      fontAwesomeClassname: "fab fa-sass"
    }
  ],
  display: true
};

const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "Иркутский Государственный Университет",
      logo: require("./assets/images/ISU.svg"),
      subHeader: "Институт математики, экономики и информатики",
      duration: "Сентябрь 2016 - Июнь 2020",
      desc: "Прикладная информатика"
      // descBullets: ["Проектирование и разработка информационных систем", ""]
    }
  ]
};

const techStack = {
  viewSkillBars: true,
  display: true,
  experience: [
    {
      Stack: "Front-end/Design",
      progressPercentage: "80%"
    },
    {
      Stack: "Back-end",
      progressPercentage: "50%"
    },
    {
      Stack: "Programming",
      progressPercentage: "50%"
    }
  ],
  displayCodersrank: false
};

const workExperiences = {
  display: true,
  experience: [
    {
      role: "Верстальщик",
      company: "ROW STUDIO",
      companylogo: require("./assets/images/no_logov2.svg"),
      date: "Декабрь 2019 – Апрель 2020",
      desc: "Верстка сайтов, создание интерактивных модулей",
      descBullets: ["HTML", "JavaScript", "SCSS"]
    },
    {
      role: "Верстальщик",
      company: "iTerra",
      companylogo: require("./assets/images/iterra.ico"),
      date: "Май 2020 – Июль 2020",
      desc: "Верстка сайтов",
      descBullets: ["HTML", "JavaScript", "SCSS"]
    },
    {
      role: "Front-end developer",
      company: "585 Золотой",
      companylogo: require("./assets/images/goldd.svg"),
      date: "Октябрь 2020 – н.в.",
      desc: "FE сайтов компании, VK Mini Apps",
      descBullets: [
        "React",
        "Mobx",
        "Bulma",
        "Formik",
        "HTML",
        "JavaScript",
        "SCSS"
      ]
    }
    // {
    //   role: "Fron-end developer",
    //   company: "Undefined",
    //   companylogo: require("./assets/images/no_logov2.svg"),
    //   date: "Now – ∞",
    //   desc: "Легко обучаюсь, пунктуальность и исполнительность стремиться в космос, добрый и веселый 🚀",
    //   descBullets: [
    //     "Reactjs / Redux / SCSS",
    //     "MongoDB / Express / Reactjs / Node.js",
    //     "Python / Yandex.Cloud / Google Cloud Platform",
    //     "HTML / JavaScript / CSS"
    //   ]
    // }
  ]
};

const openSource = {
  showGithubProfile: "true",
  display: true
};

const bigProjects = {
  title: "",
  subtitle: "",
  projects: [
    {
      image: require("./assets/images/no_logov2.svg"),
      projectName: "",
      projectDesc: "",
      footerLink: [
        {
          name: "",
          url: ""
        }
      ]
    },
    {
      image: require("./assets/images/no_logov2.svg"),
      projectName: "",
      projectDesc: "",
      footerLink: [
        {
          name: "",
          url: ""
        }
      ]
    }
  ],
  display: false
};

const achievementSection = {
  title: "",
  subtitle: "",

  achievementsCards: [
    {
      title: "",
      subtitle: "",
      image: require("./assets/images/no_logov2.svg"),
      footerLink: [
        {
          name: "",
          url: ""
        },
        {
          name: "",
          url: ""
        },
        {
          name: "",
          url: ""
        }
      ]
    },
    {
      title: "",
      subtitle: "",
      image: require("./assets/images/no_logov2.svg"),
      footerLink: [
        {
          name: "",
          url: ""
        }
      ]
    },

    {
      title: "",
      subtitle: "",
      image: require("./assets/images/no_logov2.svg"),
      footerLink: [
        {name: "", url: ""},
        {
          name: "",
          url: ""
        }
      ]
    }
  ],
  display: false
};

const blogSection = {
  title: "",
  subtitle: "",

  blogs: [
    {
      url: "",
      title: "",
      description: ""
    },
    {
      url: "",
      title: "",
      description: ""
    }
  ],
  display: false
};

const talkSection = {
  title: "",
  subtitle: "",

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: false
};

const podcastSection = {
  title: "",
  subtitle: "",

  podcast: [""],
  display: false
};

const contactInfo = {
  title: emoji("Связь со мной ☎️"),
  // subtitle: "Вы можете найти меня в любой из представленных социальных сетей и обсудить предложения там.",
  number: "+79087771718",
  email_address: "jeenjenya@gmail.com"
};

const twitterDetails = {
  userName: "username",
  display: false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
